import eventsHandler from '@/services/common/eventsHandler.js'

class packageTypeForm extends eventsHandler {
  constructor() {
    super()
    this._packageType = {}
    this._valid = false
  }

  get packageType() {
    const packageType = {
      ...this._packageType
    }
    return packageType
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._packageType.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetPackageType() {
    this._packageType = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {packageType: {}})
  }

  updateField(key, value) {
    this._packageType[key] = value
    this._executeCallbacksOf('update', {packageType: this._packageType})
    this._executeCallbacksOf('update-' + key, {key: this._packageType[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(packageType) {
    this._packageType = packageType
    this._executeCallbacksOf('update', {packageType: this._packageType})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new packageTypeForm()